<template>
    <div class="container mt-6 mb-5">
        <h1>Impressum</h1>

        <div class="mx-auto font-weight-light mt-5">
            <p>Informationspflicht lt. § 5 Abs. 1 E-Commerce-Gesetz</p>

            <p>Zeppelin Österreich GmbH<br>
                Zeppelinstr. 2<br>
                2401 Fischamend<br>
                Österreich<br>
                Telefon: +43 (0) 22 32 / 790 - 0<br>
                Telefax: +43 (0) 22 32 / 790 - 262<br>
                E-mail: <a class="no-color" href="mailto:info.at@zeppelin.com">info.at@zeppelin.com</a></p>

            <p>Firmenbuchnummer: 126414d<br>
                Firmenbuchgericht: Landesgericht Korneuburg<br>
                UID: ATU36782503<br>
                Kammerzugehörigkeit: <a class="no-color" target="_blank" href="http://firmena-z.wko.at/udbsql/ecgInfo.asp?K=1&MG=195690&NOFRAME=J">Wirtschaftskammer NÖ</a></p>

            <p>Berufsrecht: Metalltechnik für Land- und Baumaschinen (verbundenes Handwerk)<br>
                Gewerbeordnung: <a class="no-color" target="_blank" href="https://www.ris.bka.gv.at/">www.ris.bka.gv.at</a></p>

            <p>Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zurichten: <a class="no-color" target="_blank" href="http://www.ec.europa.eu/odr">www.ec.europa.eu/odr</a></p>

            <p>Interseroh Systemteilnahmenummer: 152979<br>
                ERA: 51341</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'legal-notice'
    }
</script>