<template>
    <div id="app" v-cloak>
        <Gbm-Header></Gbm-Header>
        <div class="views-wrapper row no-gutters">
            <router-view/>
        </div>
        <Gbm-Footer></Gbm-Footer>
    </div>
</template>

<script>
    import GbmHeader from "./components/page_basics/Gbm-Header";
    import GbmFooter from "./components/page_basics/Gbm-Footer";

    export default {
        components: {GbmFooter, GbmHeader}
    }
</script>

<style lang="scss" src="./style/main.scss"></style>