<template>
    <div class="machine-form-wrapper custom-form bg-primary font-mono col-12 col-lg-4 font-size-small pt-2 pb-4">

        <FormRow
                :validator-data="$v.machineData.manufacturer"
                row-type="select"
                label="Hersteller"
                :error-data="[
                {value: !$v.machineData.manufacturer.required, text: 'Bitte wählen Sie einen Hersteller aus.'}
                ]">
            <span slot="inLabel" v-tooltip.right="getTooltipOptions('Sollte sich der Hersteller nicht in der Liste befinden, wählen Sie bitte „Sonstige“.')" class="gmp-icon icon-information"></span>
            <select-dropdown
                    v-model="machineData.manufacturer"
                    :options="machineOptions"
                    placeholder="Hersteller auswählen"
                    :is-loading="dataLoading"
                    split-options></select-dropdown>
        </FormRow>

        <FormRow
                :validator-data="$v.machineData.category"
                row-type="select"
                label="Kategorie"
                :error-data="[
                {value: !$v.machineData.category.required, text: 'Bitte wählen Sie eine Kategorie aus'}
                ]">
            <span slot="inLabel" v-tooltip.right="getTooltipOptions('Sollten Sie die Kategorie nicht kennen oder sich diese nicht in der Liste befinden, wählen Sie bitte „Sonstige“.')" class="gmp-icon icon-information"></span>
            <select-dropdown
                             v-model="machineData.category"
                             :options="categoryOptions"
                             placeholder="Kategorie auswählen"
                             :is-loading="dataLoading"
                             :is-disabled="machineData.manufacturer === ''"></select-dropdown>
        </FormRow>

        <FormRow
                :validator-data="$v.machineData.type"
                row-type="select"
                label="Maschinentyp"
                :error-data="[
                {value: !$v.machineData.type.required, text: 'Bitte wählen Sie einen Maschinentyp aus'}
                ]">
            <span slot="inLabel" v-tooltip.right="getTooltipOptions('Sollte es sich bei Ihrem Maschinentyp um ein spezielles Modell handeln und sich nicht in der Liste befinden, wählen Sie bitte „Sonstige“.')" class="gmp-icon icon-information"></span>
            <select-dropdown
                    v-model="machineData.type"
                    :options="typeOptions"
                    placeholder="Maschinentyp auswählen"
                    :is-loading="dataLoading"
                    :is-disabled="typeCantBeSpecial || machineData.category === ''"></select-dropdown>
        </FormRow>

        <button id="gbm-eval-machine" class="btn btn-block btn-secondary font-weight-bold font-reset mt-3" @click="handleNext">Jetzt Bewertung erhalten</button>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { required, requiredIf } from 'vuelidate/lib/validators'
    import {debounce} from 'underscore'
    import { specialTextmixin } from '../services/specialTextMixin.service'
    import FormRow from "./FormRow";
    import SelectDropdown from "./SelectDropdown";

    export default {
        name: 'machine-form',
        components: {FormRow, SelectDropdown},
        mixins: [specialTextmixin],
        data() {
            return {
                machineData: {
                    manufacturer: '',
                    category: '',
                    type: '',
                    isSpecial: false
                },
                handleScroll: null,
                init: true
            }
        },
        mounted() {
            this.handleScroll = debounce(()=> {
                this.$store.commit('setMachineformPosition', this.$el)
            }, 50);

            window.addEventListener('scroll', this.handleScroll);

            this.machineData.manufacturer = this.selectedData.manufacturer;
            this.machineData.category = this.selectedData.category;
            this.machineData.type = this.selectedData.type;
            this.machineData.isSpecial = this.selectedData.isSpecial;

            // if this.init is true watchers don't update
            this.$nextTick(()=>{
                this.init = false;
            });
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        validations: {
            machineData: {
                manufacturer: {
                    required
                },
                category: {
                    required
                },
                type: {
                    required: requiredIf(function(data) {return !data.isSpecial})
                }
            }
        },
        watch: {
            'machineData.manufacturer': function (value) {
                if (this.init) return;
                this.machineData.category = '';
                this.machineData.isSpecial = this.valueIsSpecial(value);

                const index = this.machineOptions.indexOf(value);
                if (index !== -1) {
                    this.$store.commit('setManufacturerData', this.combinedData[index]);
                }
            },
            'machineData.category': function (value) {
                if (this.init) return;
                this.machineData.type = '';

                if (!this.valueIsSpecial(this.machineData.manufacturer)) {
                    this.machineData.isSpecial = this.valueIsSpecial(value);
                }

                const index = this.categoryOptions.indexOf(value);
                if (index !== -1) {
                    this.$store.commit('setCategoryData', this.$store.state.manufacturerData.categories[index]);
                }
            },
            'machineData.type': function (value) {
                if (this.init) return;

                if (!this.typeCantBeSpecial) {
                    this.machineData.isSpecial = this.valueIsSpecial(value);
                }

                const index = this.typeOptions.indexOf(value);
                if (index !== -1) {
                    this.$store.commit('setTypeData', this.$store.state.categoryData.models[index]);
                }

            }
        },
        methods: {
            handleNext() {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    // console.log('gtm', {
                    //     'hersteller': this.machineData.manufacturer,
                    //     'kategorie': this.machineData.category,
                    //     'maschinentyp': this.machineData.type
                    // });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'hersteller': this.machineData.manufacturer,
                        'kategorie': this.machineData.category,
                        'maschinentyp': this.machineData.type
                    });
                    window.dataLayer.push({
                        'event': 'formSubmitted',
                        'formLocation': 'Homepage'
                    });

                    this.$store.commit('setUserMachineData', this.machineData);
                    this.$router.push({name: 'condition'})
                }
            },
            getTooltipOptions(content) {
                const options = {
                    trigger: 'hover click',
                    boundariesElement: 'window'
                };

                return {content, ...options}
            }
        },
        computed: {
            ...mapState({
                combinedData: state => state.machinedata,
                selectedData: state => state.selectedData,
                dataLoading: state => state.dataLoading
            }),
            machineOptions() {
                if (!this.dataLoading) {
                    return this.combinedData.map(option => option.n);
                }
                return [];
            },
            categoryOptions() {
                let cat = this.$store.state.manufacturerData;

                if (cat) {
                    return cat.categories.map(option => option.n);
                }
                return [];
            },
            typeOptions() {
                let types = this.$store.state.categoryData;

                if (types && !this.valueIsSpecial(types.n)) {
                    types = [...types.models];

                    types.sort(function (a, b) {
                        if(a.n < b.n) { return -1; }
                        if(a.n > b.n) { return 1; }
                        return 0;
                    });

                    if (!this.valueIsSpecial(types[types.length - 1].n)) {
                      types.push({n: this.specialText});
                    }

                    return types.map(option => option.n);
                }
                return [];
            },
            typeCantBeSpecial() {
                return this.valueIsSpecial(this.machineData.manufacturer) || this.valueIsSpecial(this.machineData.category);
            }
        }
    }
</script>

<style lang="scss">
    .machine-form-wrapper {
        margin-right: -$grid-gutter-width;
        padding-left: $grid-gutter-width !important;
        padding-right: $grid-gutter-width !important;
    }
</style>