<template>
    <div class="header-image-wrapper bg-secondary">
        <div class="container">
            <div class="row no-gutters pb-3 pb-lg-0">
                <h2 class="machine-sell-heading mb-4 d-block d-md-none text-white">Verkaufen Sie Ihre gebrauchte Maschine!</h2>

                <div class="picture-wrapper">
                    <picture class="">
                        <source srcset="@/assets/images/headervisual-1200-1x.jpg, @/assets/images/headervisual-1200-2x.jpg 2x"
                                media="(min-width: 576px)">
                        <source srcset="@/assets/images/headervisual-768-1x.jpg, @/assets/images/headervisual-768-2x.jpg 2x"
                                media="(max-width: 575px)">

                        <img src="@/assets/images/headervisual-1200-1x.jpg" class="img-fluid" alt="Verkaufen Sie Ihre gebrauchte Maschine!">
                    </picture>
                </div>

                <div class="machine-sell text-white col-12 col-lg-5 mr-auto">
                    <h2 class="mb-4 d-none d-md-block">Verkaufen Sie Ihre gebrauchte Maschine!</h2>
                    <ul class="checkbox-rounded font-weight-light">
                        <li class="gmp-icon icon-checkmark-rounded">Ankauf unabhängig vom Hersteller</li>
                        <li class="gmp-icon icon-checkmark-rounded">Faire und fachmännische Bewertung</li>
                        <li class="gmp-icon icon-checkmark-rounded">Schnelle Abwicklung</li>
                        <li class="gmp-icon icon-checkmark-rounded">Prompte Bezahlung</li>
                        <li class="gmp-icon icon-checkmark-rounded">Vertrauliche Behandlung Ihrer Daten</li>
                    </ul>
                </div>

                <MachineForm class="machine-form"></MachineForm>

                <!--<div class="row no-gutters machine-overlay py-4">-->
                <!--</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    import MachineForm from "./MachineForm";
    export default {
        name: 'header-image',
        components: {MachineForm}
    }
</script>

<style lang="scss">
    $offset-top: 20px;

    .header-image-wrapper {
        .row {
            position: relative;

            .machine-sell, .machine-sell-heading {
                position: absolute;
                top: $offset-top;
                left: 0;
            }

            .machine-sell {
                @include media-breakpoint-down(sm) {
                    position: static;
                    margin-right: 0;
                }
            }

            .machine-form {
                position: absolute;
                top: $offset-top;
                right: 0;

                @include media-breakpoint-down(md) {
                    position: static;
                    margin-right: 0;
                }
            }

            .picture-wrapper {
                @include media-breakpoint-up(md) {
                    min-height: 370px;
                }

                @include media-breakpoint-up(lg) {
                    min-height: 433px;
                }
            }
        }
    }
</style>
