import store from '../store';

export function checkMachineData() {
    const range = store.state.typeData;
    console.log('machine data checked');
    return ((range && range.bn && range.bx) || store.state.selectedData.isSpecial);
    // if (!state.selectedData.manufacturer || state.selectedData.manufacturer === '') {
    //     return false;
    // }
    //
    // if (!state.selectedData.category || state.selectedData.category === '') {
    //     return false;
    // }
    //
    // if (!state.selectedData.type || state.selectedData.type === '') {
    //     return false;
    // }
    // return true;
}

export function checkConditionData() {
    const state = store.state.selectedData;

    if ((!state.year || state.year === '') && !state.isSpecial) {
        return false;
    }

    if ((!state.hours || state.hours === '') && !state.isSpecial) {
        return false;
    }
    console.log('condition data checked');
    return true;
}

export function checkFormComplete() {
    return store.state.formCompleted;
}