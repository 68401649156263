<template>
    <div class="home-wrapper">
        <HeaderImage></HeaderImage>
        <Steps></Steps>
        <Advantages></Advantages>
        <ExternalPagesBanner></ExternalPagesBanner>
        <FaqOverview></FaqOverview>
      <!--<AdvantagesText></AdvantagesText>-->
    </div>
</template>

<script>
    // @ is an alias to /src

    import Advantages from "../components/Advantages";
    import Steps from "../components/Steps";
    import HeaderImage from "../components/HeaderImage";
    import ExternalPagesBanner from "../components/ExternalPagesBanner";
    import FaqOverview from "../components/FAQOverview";
    // import AdvantagesText from "../components/AdvantagesText";

    export default {
        name: 'home',
        components: {FaqOverview, HeaderImage, Steps, Advantages, ExternalPagesBanner},
        created() {
          this.$store.dispatch('getMachineData');
        }
    }
</script>
