<template>
    <div>
        <v-select
                class="dropdown-overwrite d-none d-md-block"
                :class="{'top-items': splitOptions}"
                :value="value"
                @input="handleSelect($event)"
                :options="options"
                label="n"
                :placeholder="placeholder"
                :loading="isLoading"
                :searchable="true"
                :filterBy="filter"
                :disabled="isDisabled">
            <span slot="no-options" v-if="noOptionsText !== ''">{{ noOptionsText }}</span>
        </v-select>

        <div class="select-wrapper d-md-none">
            <select v-if="splitOptions" :disabled="isDisabled" @input="handleSelect($event.target.value)" :value="value">
                <option value="" disabled selected hidden>{{placeholder}}</option>
                <optgroup label="Top Hersteller">
                    <option v-for="(option, index) in topOptions" :key="index">{{option}}</option>
                </optgroup>
                <optgroup label="Alle Hersteller">
                    <option v-for="(option, index) in restOptions" :key="index">{{option}}</option>
                </optgroup>
            </select>
            <select v-else :disabled="isDisabled" @input="handleSelect($event.target.value)" :value="value">
                <option value="" disabled selected hidden>{{placeholder}}</option>
                <option v-for="(option, index) in options" :key="index">{{option}}</option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'select-dropdown',
        data() {
            return {}
        },
        props: {
            value: {
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            placeholder: {
                type: String,
                required: true
            },
            noOptionsText: {
              type: String,
              required: false,
              default: ''
            },
            isLoading: {
                type: Boolean,
                required: false,
                default: false
            },
            isDisabled: {
                type: Boolean,
                required: false,
                default: false
            },
            splitOptions: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            handleSelect(value) {
                this.$emit('input', value);
            },
            filter(option, label, search) {
                return (label || '').toLowerCase().startsWith(search.toLowerCase());
            }
        },
        computed: {
            topOptions() {
                return this.options.slice(0, 5);
            },
            restOptions() {
                return this.options.slice(6);
            }
        }
    }
</script>

<style lang="scss">
    /* styles for v-select can be found in style/raysono/_vue-select-overwrite.scss */

    .select-wrapper {
        position: relative;
        display: inline;
        min-height: calc(2.8125rem + 2px);

        &:after {
            content:"";
            width:0;
            height:0;
            position:absolute;
            pointer-events: none;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            top: 0.35rem;
            right: 0.75rem;
            border-top: 8px solid $black;
            opacity: 0.5;
        }

        select {
            width: 100%;
            min-height: calc(2.8125rem + 2px);
            border-radius: 5px;
            border: 1px solid $secondary;
            background-color: $white;
            /*padding-left: 7px;*/
            padding: 0.6rem 0 0.6rem 0.45rem;
            appearance: none;

            optgroup {
                color: $secondary;
                font-weight: normal;
            }

            option {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
</style>