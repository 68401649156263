<template>
    <div class="bg-light pb-5">
        <div class="container">
            <h5 class="mb-4 pt-5">Suchen Sie Nachwuchs für Ihre Flotte?</h5>
            <div class="row">
                <a class="crosslink-teaser col-12 col-md-6 p-4" href="https://www.zeppelin-cat.at/produkte/" target="_blank">
                    <div class="row h-100 p-3">
                        <div class="col-auto">
                            <span class="gmp-icon icon-link-extern"></span>
                        </div>
                        <div class="col">
                            <h5>Alle Baumaschinen im Überblick.</h5>
                            <span>Zur Produktübersicht</span>
                        </div>
                    </div>
                </a>
                <a class="crosslink-teaser col-12 col-md-6 p-4" href="https://www.zeppelin-cat.at/gebrauchtmaschinen/" target="_blank">
                    <div class="row h-100 p-3">
                        <div class="col-auto">
                            <span class="gmp-icon icon-link-extern"></span>
                        </div>
                        <div class="col">
                            <h5>Finden Sie gebrauchte <br class="d-none d-md-block">Baumaschinen zum fairen Preis</h5>
                            <span>Zur Gebrauchtmaschinen-Suche</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'crosslink-teaser'
    }
</script>

<style lang="scss">
    .crosslink-teaser {
        color: $secondary;

        &:hover {
            color: $secondary;
            text-decoration: none;

            .col > span {
                text-decoration: underline;
            }
        }

        .row {
            border: 1px solid $secondary;
            border-radius: 5px;
            box-shadow: 1px 2px 6px 0 rgba($secondary,0.3);
        }
    }
</style>
